<template>
  <div :class="{ 'wrapper-default-page commercials': isCommercialsList }" class="wrapper-main__content">
    <div class="form-content">
      <div class="d-flex flex-wrap py-2 border-bottom">
        <VocabularyTitle :title="$t('sideBar.commercials')" :per="paginationData.per_page" :entries="paginationData.total" />
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />

        <!--Search-->
        <div class="d-flex justify-content-between gap-3 mt-3 w-100">
          <div class="d-flex gap-3">
            <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />

            <MultiSelect
              v-model="selectedAdvertiser"
              label="name"
              track-by="id"
              :options="filterAdvertisersList || []"
              class="w-270px"
              :placeholder="$t('table.selectAdvertiser')"
              @input="selectAdvertiser"
            ></MultiSelect>

            <MultiSelect
              v-model="selectedBrand"
              label="name"
              track-by="id"
              :options="filterBrandsList"
              class="w-270px"
              :placeholder="$t('table.selectGroup')"
              :disabled="!(selectedAdvertiser && selectedAdvertiser.id)"
              @input="pageClick(1)"
            ></MultiSelect>
          </div>

          <search-input
            v-model="searchElements"
            :show-selector="true"
            :selected-search-type.sync="searchType"
            :options="searchFieldsList"
            @searchTable="pageClick(1)"
          />
        </div>
        <!--Search-->
      </div>

      <!--TABLE-->
      <div class="table-responsive vocabulary-table">
        <table class="table table-hover" style="white-space: nowrap">
          <thead @contextmenu.prevent="$refs.commercialsHeaderMenu.open($event, {})">
            <th scope="col" style="min-width: 50px">
              <sort-arrows :sort-string="sortString" :column="'id,'" @click="sortTable('id,', $event)" />
              ID
            </th>
            <th scope="col" style="min-width: 400px">
              <div class="resizable-div__name">
                <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
                {{ $t('table.name') }}
              </div>
            </th>
            <th scope="col">{{ $t('table.version') }}</th>
            <th scope="col" style="min-width: 150px">
              <sort-arrows :sort-string="sortString" :column="'status,'" @click="sortTable('status,', $event)" />
              {{ $t('table.status') }}
            </th>
            <th scope="col">{{ $t('table.duration') }}</th>
            <th scope="col">{{ $t('table.brand') }}</th>
            <th scope="col">{{ $t('table.co_brand') }}</th>
            <th scope="col">URL</th>
            <th scope="col" style="min-width: 150px">
              <sort-arrows :sort-string="sortString" :column="'legal_before_at,'" @click="sortTable('legal_before_at,', $event)" />
              {{ $t('table.date') }}
            </th>
            <th scope="col" style="min-width: 100px">
              <sort-arrows :sort-string="sortString" :column="'is_visible,'" @click="sortTable('is_visible,', $event)" />
              {{ $t('table.isVisible') }}
            </th>
            <th>
              {{ $t('table.externalId') }}
            </th>
            <th v-if="isAsRegIdVisible" scope="col" style="min-width: 50px">
              <sort-arrows :sort-string="sortString" :column="'as_reg_id,'" @click="sortTable('as_reg_id,', $event)" />
              {{ $t('table.asRegId') }}
            </th>
            <th v-if="isAsApproveVisible" scope="col" style="min-width: 50px">
              <sort-arrows :sort-string="sortString" :column="'as_is_approved,'" @click="sortTable('as_is_approved,', $event)" />
              {{ $t('table.asApprove') }}
            </th>
          </thead>
          <tbody>
            <tr v-if="commercialsStatus !== 'success'">
              <td colspan="11"><SpinnerLoader :loading="commercialsStatus" /></td>
            </tr>
            <tr
              v-for="(row, index) in tableData"
              :key="index"
              class="cursor-pointer"
              @contextmenu.prevent="[(isOpenModal = 'modal-commercial'), (modalName = 'edit'), (currentCommercial = row)]"
            >
              <td>
                {{ row.id }}
              </td>
              <td class="overflow-hidden text-overflow-ellipsis" style="max-width: 400px">
                <button
                  class="btn-icon d-inline-block"
                  :title="canEdit ? $t('table.edit') : $t('table.details')"
                  @click="[(isOpenModal = 'modal-commercial'), (modalName = 'edit'), (currentCommercial = row)]"
                >
                  <b-icon icon="pencil"></b-icon>
                </button>
                <button
                  v-if="canDelete"
                  class="btn-icon d-inline-block"
                  :title="$t('table.delete')"
                  @click="[(isOpenModal = 'modal-commercial'), (modalName = 'delete'), (currentCommercial = row)]"
                >
                  <b-icon icon="trash"></b-icon>
                </button>
                {{ row.name }}
              </td>
              <td>
                {{ row.commercial_version_type ? row.commercial_version_type.name : '' }}
              </td>
              <td>
                {{ row.status === 0 ? $t('table.new') : $t('table.onChannel') }}
              </td>
              <td>
                {{ row.duration }}
              </td>
              <td>
                {{ row.brand ? row.brand.name : '' }}
              </td>
              <td>
                {{ row.co_brand ? row.co_brand.name : '' }}
              </td>
              <td>
                <template v-if="row.url">
                  <a :href="row.url" target="_blank" rel="noopener noreferrer">{{ $t('table.link') }}</a>
                </template>
              </td>
              <td>
                <template v-if="row.legal_before_at">
                  {{ row.legal_before_at | convertDate }}
                </template>
              </td>
              <td>
                <b-form-checkbox
                  :id="`checkbox-1-${index}`"
                  :checked="row.is_visible"
                  :name="`checkbox-1-${index}`"
                  :disabled="!canEdit || !canUpdateIsVisible"
                  @change="toggleVisibility(row)"
                >
                </b-form-checkbox>
              </td>
              <td>
                {{ row.external_id }}
              </td>
              <td v-if="isAsRegIdVisible">
                {{ row.as_reg_id }}
              </td>
              <td v-if="isAsApproveVisible">
                <b-form-checkbox
                  :id="`checkbox-2-${index}`"
                  :checked="row.as_is_approved"
                  :name="`checkbox-2-${index}`"
                  :disabled="true"
                >
                </b-form-checkbox>
              </td>
            </tr>
            <tr v-if="commercialsStatus === 'success' && tableData && tableData.length === 0">
              <td colspan="11">
                <div class="d-flex w-100 justify-content-center">
                  {{ $t('table.noContent') }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <div class="d-flex justify-content-between gap-2">
          <b-button
            v-if="$checkPermissions('commercial.create')"
            variant="primary"
            @click="[(isOpenModal = 'modal-project'), (modalName = 'add'), (currentCommercial = {})]"
          >
            {{ $t('table.addNewCommercials') }}
          </b-button>
          <b-button v-if="isCommercialsList" @click="$router.go(-1)">
            {{ $t('broadcastSideBar.exit') }}
          </b-button>
        </div>
      </div>
      <!--FOOTER-->

      <ModalCommercial
        :commercial="currentCommercial"
        :modal-name="modalName"
        :is-open-modal="isOpenModal"
        :can-edit="canEdit"
        @update="paramsData"
        @isOpenModal="isOpenModal = null"
      />
    </div>
    <ContextMenu ref="commercialsHeaderMenu">
      <ContextMenuItem @click.native="isAsRegIdVisible = !isAsRegIdVisible">
        <b-icon :icon="isAsRegIdVisible ? 'eye' : 'eye-slash'" /> {{ $t('table.asRegId') }}
      </ContextMenuItem>
      <ContextMenuItem @click.native="isAsApproveVisible = !isAsApproveVisible">
        <b-icon :icon="isAsApproveVisible ? 'eye' : 'eye-slash'" /> {{ $t('table.asApprove') }}
      </ContextMenuItem>
    </ContextMenu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MultiSelect from '@/components/MultiSelect';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import errorsHandler from '@/utils/errorsHandler';
import PaginateWrapper from '@/components/PaginateWrapper';
import SearchDropdown from '@/components/SearchDropdown';
import SearchInput from '@/components/SearchInput';
import convertDate from '../filters/convertDate';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ModalCommercial from '@/components/Commercials/ModalCommercial';
import sortTableMixin from '@/mixins/sortTable';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import SortArrows from '../components/SortArrows';

export default {
  name: 'Commercials',
  components: {
    PaginateWrapper,
    SpinnerLoader,
    VocabularyTitle,
    MultiSelect,
    SearchDropdown,
    SearchInput,
    ModalCommercial,
    SortArrows,
    ContextMenu,
    ContextMenuItem,
  },
  filters: {
    convertDate,
  },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
    advertiser_id: { type: [String, Number], default: undefined },
    brand_id: { type: [String, Number], default: undefined },
    search_type: { type: String, default: undefined },
  },
  data() {
    return {
      isAsRegIdVisible: false,
      isAsApproveVisible: false,
      selectedAdvertiser: '',
      selectedBrand: '',
      rowId: '',
      triggerSearch: '',
      tableData: [],
      paginationData: '',
      searchElements: '',
      filterColumn: 'name',
      filterPage: 1,
      selectedPerPage: '100',

      currentCommercial: null,
      modalName: null,
      isOpenModal: null,

      filterAdvertisersList: [],
      filterBrandsList: [],
      searchType: 'name',
      sortString: '-id,',
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      isLocale: 'isLocale',
      commercials: 'getCommercialsList',
      commercialsStatus: 'getCommercialsStatus',
      modalEditCommercials: 'getModalEditCommercials',
      advertisersList: 'getAdvertisersList',
      commercialVersionTypes: 'getCommercialVersionTypes',
      commercialTypeWithoutAll: 'getCommercialTypeWithoutAll',
      brandsList: 'getBrandsList',
    }),
    canEdit: function () {
      return this.$checkPermissions('commercial.update');
    },
    canDelete: function () {
      return this.$checkPermissions('commercial.delete');
    },
    isCommercialsList: function () {
      return this.$route.name === 'commercialsList';
    },
    searchFieldsList() {
      return [
        { value: 'name', text: this.$i18n.t('table.name') },
        { value: 'id', text: 'ID' },
        { value: 'external_id', text: this.$i18n.t('table.externalId') },
        { value: 'as_reg_id', text: this.$i18n.t('table.asRegId') },
      ];
    },
    canUpdateIsVisible() {
      return this.$checkPermissions('commercial.update_is_visible');
    },
  },

  watch: {
    commercials() {
      this.setTableData();
    },
  },

  created() {
    document.title = this.$i18n.t('sideBar.commercials') + ' – OpenMediaLogic';
  },

  mounted() {
    this.getCommercialsInfo();
  },
  destroyed() {
    this.$store.commit('clearAdvertisersList');
    this.$store.commit('clearBrandsList');
    this.$store.commit('clearCommercialsList');
  },

  methods: {
    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_COMMERCIALS', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        'filter[name]': this.searchElements !== '' && this.searchType === 'name' ? this.searchElements : null,
        'filter[id]': this.searchElements !== '' && this.searchType === 'id' ? this.searchElements : null,
        'filter[brand_id]': this.selectedBrand ? this.selectedBrand.id : null,
        'filter[external_id]': this.searchElements !== '' && this.searchType === 'external_id' ? this.searchElements : null,
        'filter[as_reg_id]': this.searchElements !== '' && this.searchType === 'as_reg_id' ? this.searchElements : null,
        sort: this.sortString,
        include: 'commercialVersionType,brand,coBrand,commercialType',
      });
      this.updateQuery();
    },

    async selectAdvertiser() {
      if (this.selectedAdvertiser && this.selectedAdvertiser.id) {
        await this.$store.dispatch('GET_BRANDS', {
          'filter[advertiser_id]': this.selectedAdvertiser.id,
          per_page: 1000,
        });
        // this.filterBrandsList = [{name: this.$i18n.t('table.all'), id: null}, ...this.brandsList];
        this.filterBrandsList = this.brandsList;
      } else {
        this.filterBrandsList = [];
      }
      if (this.selectedBrand) {
        this.selectedBrand = '';
        this.pageClick(1);
      }
    },

    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },

    // get table info
    setTableData() {
      if (this.commercials) {
        this.tableData = this.commercials.data;
        this.paginationData = this.commercials.pagination;
        if (this.filterPage > this.commercials.pagination.last_page) {
          this.filterPage = this.commercials.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },

    async getCommercialsInfo() {
      await this.$store.dispatch('GET_ADVERTISERS', { per_page: 2000 });
      this.filterAdvertisersList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.advertisersList];
      await this.restoreFromQuery('commercials');
      this.paramsData();
    },

    // Toggle commercial visibility
    async toggleVisibility(row) {
      const commercialId = row.id;
      const formData = {
        is_visible: !row.is_visible,
      };
      await this.$store.dispatch('PUT_COMMERCIALS', {
        commercialId,
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.changedCommercialVisibility'),
            text: row.name,
          });
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
table thead
  .resizable-div__name
    overflow: auto
    min-width: 400px

  &:hover .resizable-div__name
    resize: horizontal

.wrapper-default-page.commercials
  padding: 46px 0 0
</style>
